/*
 * File: Miscellaneous.service.ts
 * Project: autobuyclient
 * File Created: Friday, 6th September 2024 11:58:26
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 10th September 2024 09:59:07
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import { LanguageType } from "Types/language.type";
import { PackType } from "Types/pack.type";
import generalQueryParams from "Types/params.type";
import { buildURL, buildAxiosHeader, buildAxiosHeaderFile } from "Utils/Request.utils";
import Axios from "axios";

class MiscellaneousService {

    static async getServerTime(): Promise<number> {
        const timestamp = Date.now()
        try {
            const myRet = await Axios.get(`${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.SERVERTIME}`, buildAxiosHeader());
            return myRet.data.time
        } catch (e: any) {
            console.error("local", "getServerTime", "error", `Unable to retrieve time. Using local user time.` )
        }
        return timestamp;
    };

};

export default MiscellaneousService;

