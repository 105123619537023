/*
 * File: ValidationSuccess.tsx
 * Project: autobuyclient
 * File Created: Monday, 18th September 2023 10:55:03
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 10th September 2024 05:20:50
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useLang } from "Router/LangueProvider";
import { Fragment, useEffect, useState } from "react";
import Styles from "Content/CreateAccount/ValidationSuccess.module.css";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import CGVService from "Services/CGV/CGVService";
import RouterPathResolver from "Config/Router/RouterPathResolver";

const CGVResult = () => {
    const navigate = useNavigate();
    const {translator} = useLang();
    const [spinner, setSpinner] = useState(true);
    const [success, setSuccess] = useState(true);
    //tslint:disable-next-line
    const [params, setParams] = useSearchParams();
    const result = useLoaderData();

    useEffect(() => {
        let userID = params.get("external_id");
        let sr_id = params.get("signature_request_id");
        if (result !== undefined) {
            if (result === "success") {
                if (userID === null || sr_id === null) {
                    CGVService.setCGVRequestStatus(userID ?? "", "failure", sr_id ?? "").then(() => {
                        setSuccess(false);
                        setSpinner(false);
                    }).catch(() => {
                        setSuccess(false);
                        setSpinner(false);
                    });
                } else  {
                    CGVService.setCGVRequestStatus(userID, "success", sr_id).then(() => {
                        setSuccess(true);
                        setSpinner(false);
                    }).catch(() => {
                        setSuccess(false);
                        setSpinner(false);
                    });;
                }
            } else {
                CGVService.setCGVRequestStatus(userID ?? "", "failure", sr_id ?? "").then(() => {
                    setSuccess(false);
                    setSpinner(false);
                }).catch(() => {
                    setSuccess(false);
                    setSpinner(false);
                });;
            }
        } else {
            CGVService.setCGVRequestStatus(userID ?? "", "failure", sr_id ?? "").then(() => {
                setSuccess(false);
                setSpinner(false);
            }).catch(() => {
                setSuccess(false);
                setSpinner(false);
            });;
        }
    }, []);

    return (
        <div className={`${Styles.createAccountPage}`}>
            {
                spinner ?
                    <div className={`${Styles.spinnerHolder}`}>
                        <LoadingScreen />
                    </div>
                    :
                    <Fragment>
                        <img loading="lazy" className={`${Styles.backgroundImg}`} src="/assets/parking.png" alt={translator.decode("image.background")} />
                        <div className={`${Styles.formHolder}`} >
                            {
                                success ?
                                <p>{translator.decode("user.create.cgv_success")}</p>
                                :
                                <p>{translator.decode("user.create.cgv_error")}</p>
                            }
                            <button className="btn btn-primary" onClick={() => navigate(RouterPathResolver.pathHome.path, { replace: true })}>{translator.decode("modal.backHome")}</button>
                            {/* {
                                success ?
                                <a href="" download={}></a>
                                : null
                            } */}
                        </div>
                    </Fragment>
            }
        </div>
    )
}

export default CGVResult;
